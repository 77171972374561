
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.wrapper {
  height: 100%;
}

.header_wrapper {
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 0 24px 8px;
  background-color: $color-white;
  transition: box-shadow 0.2s ease;

  @include mobile {
    padding: 0 16px 8px;
  }

  &_shadow {
    box-shadow: 0 10px 8px -8px rgb(42 39 65 / 10%);
  }
}

.tabs_and_buttons {
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.tab {
  padding: 10px 0;
  color: $color-gray-500;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  cursor: pointer;

  &_container {
    display: flex;
    gap: 16px;
    align-items: center;
    width: 100%;
  }

  &_active {
    color: $color-gray-700;
  }

  @include mobile {
    flex: 5;
    height: 44px;
    padding: 4px 10px;
  }
}

.category {
  display: flex;
  gap: 8px;
  padding: 8px 0;
  overflow-x: auto;

  @include mobile {
    margin: 0 -16px;
    padding: 8px 16px;
  }

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  &_item {
    flex-shrink: 0;
    padding: 10px 12px;
    color: $color-gray-600;
    font-weight: 600;
    font-size: 13px;
    border: 1px solid $color-gray-200;
    border-radius: 999px;
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: transparent;

    --highlight-color: transparent;

    @media (hover: hover) {
      &:hover {
        background-color: $color-gray-50;
      }
    }

    &_active {
      color: $color-white;
      background-color: $color-primary-default;

      @media (hover: hover) {
        &:hover {
          background-color: $color-primary-default-hover;
        }
      }
    }
  }
}

.content {
  display: grid;
  grid-gap: 24px 16px;
  grid-template-columns: repeat(4, 1fr);
  box-sizing: border-box;
  margin-top: -24px;

  @include mobile {
    grid-template-columns: repeat(2, 1fr);
    height: auto;
    margin-top: -16px;
  }
}

.skeleton_box {
  position: relative;
  display: flex;
  flex-flow: column;
  gap: 8px;
  box-sizing: border-box;
  padding: 8px;

  &_1 {
    width: 100%;
    max-width: 218px;
    height: 0;
    padding-bottom: 65%;
    background-color: $color-gray-200;
    border-radius: 4px;
  }

  &_2 {
    width: 100%;
    max-width: 178px;
    height: 70%;
    max-height: 24px;
    list-style: none;
    background-color: $color-gray-200;
    border-radius: 4px;
  }
}

.item {
  position: relative;
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
  list-style: none;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    .item_image {
      transform: scale(1.08);
    }
  }

  @include mobile {
    height: auto;
  }

  &_image_wrapper {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 0;
    margin-bottom: 8px;
    padding-bottom: 65%;
    overflow: hidden;
    cursor: pointer;
  }

  &_image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
    box-shadow: inset 0 0 0 1px rgba($color-gray-700, 12%);
    transition: transform 0.2s ease-in-out;
  }

  &_category {
    color: $color-gray-500;
    font-weight: 600;
    font-size: 12px;
  }

  &_name {
    padding: 3px 0;
    overflow: hidden;
    font-weight: 500;
    font-size: 13px;
    text-overflow: ellipsis;
  }

  &_new {
    position: absolute;
    top: 8px;
    left: 8px;
    padding: 2px 4px 2px 5px;
    color: $color-white;
    font-weight: 700;
    font-size: 13px;
    background-color: $color-red-default;
    border-radius: 9999px;

    @include mobile {
      font-weight: 600;
      font-size: 12px;
    }
  }
}

.empty_content {
  width: 100%;
  min-height: 500px;

  @include flex-center;

  &_text {
    @include h4;

    display: flex;
    flex-flow: column;
    gap: 8px;
    align-items: center;

    .subtitle {
      @include subtitle('m');

      color: $color-gray-600;
    }
  }
}
