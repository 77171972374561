
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
@mixin hide-password-eye() {
  &::-ms-reveal,
  &::-webkit-contacts-auto-fill-button,
  &::-webkit-credentials-auto-fill-button,
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    display: none !important;
  }
}

.input {
  @include space-x(8px);

  display: inline-flex;
  align-items: center;
  color: $color-gray-700;
  background-color: $color-white;
  border: 1px solid $color-gray-300;
  border-radius: 8px;

  .slot {
    display: flex;
    line-height: initial;
    white-space: nowrap;
  }

  .control {
    width: 100%;
    padding: 0;
    color: $color-gray-700;
    font: inherit;
    text-overflow: ellipsis;
    background-color: transparent;
    border: none;
    outline: none;

    &[type='password'] {
      @include hide-password-eye;
    }

    &::placeholder {
      color: $color-gray-500;
    }

    &:placeholder {
      text-overflow: ellipsis;
    }

    &:placeholder-shown {
      text-overflow: ellipsis;
    }

    // MEMO: https://developer.mozilla.org/en-US/docs/Web/CSS/:autofill
    &:autofill {
      background-color: transparent;
    }
  }

  &:focus-within {
    border: 1px solid $color-primary-default;
  }

  &.error {
    border-color: $color-red-default;
  }

  &.disabled {
    color: $color-gray-400;

    .control {
      color: $color-gray-400;

      &::placeholder {
        color: $color-gray-400;
      }
    }
  }

  &.readOnly,
  &.disabled {
    background-color: $color-gray-100;
    border-color: $color-gray-200;
  }

  &.fullWidth {
    display: flex;
    width: 100%;
  }

  &.size {
    &_md {
      @include subtitle('m');

      .control {
        height: 38px; // 40px

        &:first-child {
          padding-left: 12px;
        }

        &:last-child {
          padding-right: 12px;
        }
      }

      .slot {
        &:first-child {
          padding-left: 12px;
        }

        &:last-child {
          padding-right: 12px;
        }
      }

      @include mobile {
        @include body-1('m');

        .control {
          height: 36px; // 38px
        }

        .slot {
          &:first-child {
            padding-left: 12px;
          }

          &:last-child {
            padding-right: 12px;
          }
        }
      }
    }

    &_sm {
      @include body-2('m');

      .control {
        height: 32px; // 34px

        &:first-child {
          padding-left: 10px;
        }

        &:last-child {
          padding-right: 10px;
        }
      }

      .slot {
        &:first-child {
          padding-left: 10px;
        }

        &:last-child {
          padding-right: 10px;
        }
      }

      @include mobile {
        @include body-1('m');

        .control {
          height: 36px; // 38px
        }

        .slot {
          &:first-child {
            padding-left: 12px;
          }

          &:last-child {
            padding-right: 12px;
          }
        }
      }
    }
  }
}
