
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.content {
  padding-top: 16px;
}

.text_filed_label {
  color: $color-gray-700;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
}

.footer_content {
  margin: 12px -8px -8px;
}

.loading_text {
  display: flex;
  align-items: center;
  color: $color-gray-500;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
}
