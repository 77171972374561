
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.row {
  display: inline-flex;
  align-items: center;
  color: $color-gray-700;
  line-height: 1.2;

  @include space-x(8px);

  .col {
    flex: 1;

    @include space-y(4px);
  }

  .checkbox {
    flex: none;
  }

  .label {
    @include body-1('sb');
  }

  .text {
    @include caption-1('r');

    color: $color-gray-600;
  }

  &.fullWidth {
    display: flex;
  }
}

.disabled {
  .label {
    color: $color-gray-400;
  }

  .checkbox {
    color: $color-gray-300;
  }
}

.checked.disabled {
  .label {
    color: $color-gray-600;
  }
}

.checkbox {
  position: relative;
  z-index: 0;
  display: inline-flex;

  [data-checkbox-icon] {
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $color-gray-300;
    background-color: $color-white;
    border: 2px solid $color-gray-300;
    border-radius: 2px;
    transition: box-shadow 0.1s;
    inset: 2px;
  }

  [data-checkbox-control] {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0;
    inset: 0;

    &:checked + [data-checkbox-icon] {
      color: $color-white;
      background-color: $color-primary-default;
      border-color: $color-primary-default;
    }

    &:disabled + [data-checkbox-icon] {
      color: $color-gray-200;
      background-color: $color-gray-50;
      border-color: $color-gray-200;
    }

    &:checked:disabled + [data-checkbox-icon] {
      color: $color-gray-50;
      background-color: $color-primary-disabled;
      border-color: $color-primary-disabled;
    }

    &:focus-visible + [data-checkbox-icon] {
      box-shadow: $focus-ring;
    }
  }
}

.circle {
  [data-checkbox-icon] {
    border-radius: 9999px;
  }
}
