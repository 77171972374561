// 👉 figma link: https://www.figma.com/file/ORIPtydAYEVIrAy0vZ0f4t/%F0%9F%8E%A8ZEP-Components?node-id=3928%3A57222&t=t176iysNjuZ87M0T-4

@mixin shadow-s {
  box-shadow: 0 1px 4px rgb(42 39 65 / 8%);
}

@mixin shadow-m {
  box-shadow: 0 2px 10px rgb(42 39 65 / 10%);
}

@mixin shadow-l {
  box-shadow: 0 4px 8px 3px rgb(42 39 65 / 10%);
}

@mixin shadow-xl {
  box-shadow: 0 8px 16px 3px rgb(42 39 65 / 18%);
}

@mixin shadow-2xl {
  box-shadow: 0 10px 18px -2px rgb(42 39 65 / 22%);
}

// reverse
@mixin shadow-s-reverse {
  box-shadow: 0 -1px 4px rgb(42 39 65 / 8%);
}

@mixin shadow-m-reverse {
  box-shadow: 0 -2px 10px rgb(42 39 65 / 10%);
}

@mixin shadow-l-reverse {
  box-shadow: 0 -4px 8px 3px rgb(42 39 65 / 10%);
}

@mixin shadow-xl-reverse {
  box-shadow: 0 -8px 16px 3px rgb(42 39 65 / 18%);
}

@mixin shadow-2xl-reverse {
  box-shadow: 0 -10px 18px -2px rgb(42 39 65 / 22%);
}
