
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
// !! 전체 서비스에 모든 영향을 미치는 컴포넌트입니다.
// !! 수정하려면, 컴포넌트 담당자와 미리 상의해주세요.

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  line-height: 1 !important;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  box-shadow: inset 0 0 0 1px rgba($color-black, 3%);

  @include space-x(4px);

  &.fullWidth {
    width: 100%;
  }

  @include focus-ring;
}

.size {
  &_lg {
    height: 48px;
    padding: 0 16px;
    border-radius: 8px;

    @include subtitle('sb');
  }

  &_md {
    height: 40px;
    padding: 0 12px;
    border-radius: 8px;

    @include body-1('sb');
  }

  &_sm {
    height: 36px;
    padding: 0 12px;
    border-radius: 6px;

    @include body-1('sb');
  }

  &_xs {
    height: 30px;
    padding: 0 10px;
    border-radius: 4px;

    @include caption-1('sb');
  }

  &_xxs {
    height: 26px;
    padding: 0 8px;
    border-radius: 4px;

    @include caption-1('sb');
  }
}

.variant {
  &_primary {
    color: $color-white;
    background-color: $color-primary-default;

    @media (hover: hover) {
      &:hover {
        background-color: #{$color-primary-default-hover};
      }
    }

    &:active {
      background-color: $color-primary-default-hover;
    }

    &[aria-disabled='true'],
    &:disabled {
      color: $color-white;
      background-color: $color-primary-disabled;
      border-color: $color-primary-disabled;
    }
  }

  &_secondary {
    color: $color-primary-default;
    background-color: $color-primary-background;

    @media (hover: hover) {
      &:hover {
        background-color: $color-primary-background-hover;
      }
    }

    &:active {
      background-color: $color-primary-background-hover;
    }

    &[aria-disabled='true'],
    &:disabled {
      color: rgba($color-primary-default, 0.4);
      background-color: $color-gray-50;
    }
  }

  &_sub {
    color: $color-gray-600;
    background-color: $color-gray-100;

    @media (hover: hover) {
      &:hover {
        background-color: $color-gray-200;
      }
    }

    &:active {
      background-color: $color-gray-200;
    }

    &[aria-disabled='true'],
    &:disabled {
      color: rgba($color-gray-600, 0.4);
      background-color: $color-gray-50;
    }
  }

  &_alert {
    color: $color-red-default;
    background-color: $color-red-background;

    @media (hover: hover) {
      &:hover {
        background-color: $color-red-background-hover;
      }
    }

    &:active {
      background-color: $color-red-background-hover;
    }

    &[aria-disabled='true'],
    &:disabled {
      color: rgba($color-red-default, 0.4);
      background-color: $red-disable;
    }
  }

  &_white {
    color: $color-gray-600;
    background-color: $color-white;

    @media (hover: hover) {
      &:hover {
        background-color: $color-gray-50;
      }
    }

    &:active {
      background-color: $color-gray-50;
    }

    &:disabled {
      color: rgba($color-gray-600, 0.4);
    }
  }
}

.slot > * {
  display: block;
  margin: auto;
}

.loading {
  position: relative;
  animation: rotate 2s linear infinite;

  .circle {
    width: 100%;
    height: 100%;
    animation: animate 6s linear infinite;
    fill: none;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-dasharray: 30;
    stroke-dashoffset: 30;
  }
}

.rounded {
  border-radius: 9999px;
}

.transparent {
  background-color: transparent;
  box-shadow: none;

  &.variant {
    &_primary {
      color: $color-primary-default;

      @media (hover: hover) {
        &:hover {
          background-color: $color-gray-50;
        }
      }

      &:active {
        background-color: $color-gray-50;
      }

      &[aria-disabled='true'],
      &:disabled {
        color: rgba($color-primary-disabled, 70%);
        background-color: transparent;
        border-color: transparent;
      }
    }

    &_sub {
      color: $color-gray-600;

      @media (hover: hover) {
        &:hover {
          background-color: $color-gray-50;
        }
      }

      &:active {
        background-color: $color-gray-50;
      }

      &[aria-disabled='true'],
      &:disabled {
        color: rgba($color-gray-600, 70%);
        background-color: transparent;
        border-color: transparent;
      }
    }

    &_alert {
      color: $color-red-default;

      @media (hover: hover) {
        &:hover {
          background-color: $red-disable;
        }
      }

      &:active {
        background-color: $red-disable;
      }

      &[aria-disabled='true'],
      &:disabled {
        color: rgba($color-red-default, 70%);
        background-color: transparent;
        border-color: transparent;
      }
    }
  }
}

.bordered {
  &.variant {
    &_white {
      box-shadow: inset 0 0 0 1px $color-gray-200;
    }
  }
}

@keyframes animate {
  0%,
  100% {
    stroke-dashoffset: 150;
  }

  50% {
    stroke-dashoffset: 0;
  }

  50.1% {
    stroke-dashoffset: 300;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
