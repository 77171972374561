// Do not edit directly
// Generated on Wed, 12 Jul 2023 08:46:20 GMT

$color-purple-default: #924aff;
$color-blue-default: #0d94ff;
$color-blue-light: #61bdff;
$color-green-default: #15e42a;
$color-green-light: #60ff70;
$color-yellow-default: #ffd600;
$color-yellow-light: #ffeb3a;
$color-red-default-hover: #ef4141;
$color-red-default: #ff5353;
$color-red-background-hover: #ffe8f1;
$color-red-background: #fff2f7;
$color-mint-dark-hover: #049382;
$color-mint-dark: #05b8a2;
$color-mint-default: #15e4bf;
$color-mint-light: #74fada;
$color-mint-background: #e5fff9;
$color-primary-gradient: #994aff;
$color-primary-default-hover: #5246cc;
$color-primary-default: #6758ff;
$color-primary-disabled: #e9e8ff;
$color-primary-background-hover: #e9e8ff;
$color-primary-background: #f3f2ff;
$color-gray-default-hover: #4b4d52;
$color-gray-default: #5e6066;
$color-gray-700: #27262e;
$color-gray-600: #5e6066;
$color-gray-500: #8c9097;
$color-gray-400: #bec3cc;
$color-gray-300: #d5d9e0;
$color-gray-200: #e9eaf0;
$color-gray-100: #f3f5f9;
$color-gray-50: #f8f9fc;
$color-white: #fff;
$color-black: #000;
$breakpoint-mobile-sm: 420px;
$breakpoint-mobile: 560px;
$breakpoint-tablet: 1024px;
$breakpoint-laptop-sm: 1280px;
$breakpoint-laptop: 1440px;
$primary-gradient-purple: linear-gradient(90deg, #6758ff 0%, #994aff 100%);
$primary-gradient-pink: linear-gradient(90deg, #f75dff 0%, #9f55ff 93.9%);
