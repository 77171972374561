
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.select {
  position: relative;
  display: inline-flex;
  width: var(--select-width, auto);
  vertical-align: middle;

  .button {
    display: inline-flex;
    align-items: center;
    width: 100%;
    min-width: 100px;
    padding: 0 12px;
    color: $color-gray-700;
    text-align: left;
    background-color: $color-white;
    border: 1px solid $color-gray-300;
    border-radius: 8px;
    outline: none;
    transition: box-shadow 0.1s;

    @include space-x(4px);

    .text {
      flex: 1;
      overflow: hidden;
      line-height: initial !important;
      white-space: nowrap;
      text-overflow: ellipsis;

      &.empty {
        color: $color-gray-500;
      }
    }

    .icon {
      margin-right: -6px;
      transform: rotate(180deg);
    }

    &[data-headlessui-state*='open'] .icon {
      transform: rotate(0);
    }

    &:focus-visible {
      border-color: $color-primary-default;
    }

    &.size {
      &_sm {
        min-height: 34px;

        @include body-2('m');

        @include mobile {
          min-height: 38px;

          @include body-1('m');
        }
      }

      &_md {
        min-height: 40px;

        @include subtitle('m');

        @include mobile {
          min-height: 38px;

          @include body-1('m');
        }
      }
    }

    &.open {
      border-color: $color-primary-default;
    }

    &.disabled {
      color: $color-gray-400;
      background-color: $color-gray-100;
      border-color: $color-gray-300;
    }

    &.placeholder {
      color: $color-gray-500;
    }

    &.success {
      border-color: $color-green-default;
    }

    &.error {
      border-color: $color-red-default;
    }
  }

  &.fullWidth {
    display: flex;
    width: 100%;
  }
}

// !! 스크롤을 막기 위해 적용한 스타일 수정 금지
.overlay {
  position: fixed;
  inset: 0;
  z-index: 1;
}

.list {
  position: absolute;
  top: 100%;
  z-index: 10;
  width: 100%;
  max-height: 200px;
  padding: 4px 8px;
  overflow: auto;
  overflow: overlay;
  background-color: $color-white;
  border: 1px solid $color-gray-200;
  border-radius: 8px;
  outline: none;

  @include space-y(2px);
  @include shadow-s;
}

.item {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 36px;
  padding: 0 10px;
  white-space: nowrap;
  text-align: left;
  background-color: $color-white;
  border-radius: 4px;
  outline: none;
  cursor: pointer;

  @include body-2('m');
  @include space-x(4px);

  &.placeholder {
    color: $color-gray-500;
  }

  &.danger {
    color: $color-red-default;
  }

  .text {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .icon {
    margin-right: -6px;
  }

  &[data-headlessui-state*='disabled'] {
    color: $color-gray-400;
  }

  &[data-headlessui-state*='active'] {
    background-color: $color-gray-50;
  }

  &[data-headlessui-state*='selected'] {
    color: $color-primary-default;
    background-color: $color-primary-background;
  }
}
