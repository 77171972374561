@mixin laptop {
  @media (max-width: $breakpoint-laptop) {
    @content;
  }
}

@mixin laptop-sm {
  @media (max-width: $breakpoint-laptop-sm) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: $breakpoint-tablet) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: $breakpoint-mobile) {
    @content;
  }
}

@mixin mobile-sm {
  @media (max-width: $breakpoint-mobile-sm) {
    @content;
  }
}

@mixin flex($flow, $align, $justify, $gap) {
  display: flex;
  flex-flow: $flow;
  gap: $gap;
  align-items: $align;
  justify-content: $justify;
}

@mixin border($size, $style, $color) {
  box-sizing: border-box;
  border: $size $style $color;
}

@mixin space-x($value) {
  & > :not([hidden]) ~ :not([hidden]) {
    margin-left: $value;
  }
}

@mixin space-y($value) {
  & > :not([hidden]) ~ :not([hidden]) {
    margin-top: $value;
  }
}

@mixin hide-scrollbar() {
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }
}

@mixin focus-ring {
  &:focus-visible {
    outline: none;
    box-shadow: $focus-ring;
  }
}

@mixin text-clamp($lines: 2, $line-height: false, $important: false) {
  @if $important {
    display: -webkit-inline-box !important;
  } @else {
    display: -webkit-inline-box;
  }

  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
  overflow: hidden;

  @if $line-height {
    max-height: $line-height * $lines * 1px;
  }
}

@mixin dimmed {
  background-color: rgb(0 0 0 / 24%);
}

@mixin text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin hide-webkit-search-decorations {
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}
