
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.progress {
  color: var(--progress-color, $color-primary-default);
  transform: rotate(-90deg);

  .circle {
    transition: stroke-dashoffset 0.1s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &.loading {
    animation: progress 2s linear infinite;

    .circle {
      transition: none;
      animation: circle 6s linear infinite;
      stroke-dasharray: 30;
    }
  }

  &.color {
    &_primary {
      --progress-color: #{$color-primary-default};
    }

    &_white {
      --progress-color: #{$color-white};
    }
  }
}

@keyframes progress {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes circle {
  0%,
  100% {
    stroke-dashoffset: 150;
  }

  50% {
    stroke-dashoffset: 0;
  }

  50.1% {
    stroke-dashoffset: 300;
  }
}
