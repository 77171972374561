
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.footer {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  margin: auto;
  padding-top: 15px;
  padding-bottom: 75px;
  background-color: $color-white;

  .inner {
    width: 90%;
    max-width: $container-max-width;
  }

  @include mobile-sm {
    padding-bottom: 30px;
  }

  .footer_width {
    display: flex;
    gap: 15px;
    align-items: center;
    width: 100%;
    margin-top: auto;
    padding-bottom: 24px;

    @include tablet {
      flex-flow: column;
      gap: 25px;
      align-items: flex-start;
    }

    .footer_logo {
      display: flex;
      align-items: center;

      > img {
        max-width: none;
      }
    }

    .menu_container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      font-size: 15px;

      @include mobile {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
        font-size: 14px;
      }

      a,
      button {
        position: relative;
        padding: 0;
        color: #333;
        font-weight: 400;
        cursor: pointer;
      }

      span.vertical_bar {
        width: 25px;
        color: #d5d5d5;
        text-align: center;
        cursor: default;

        @include mobile {
          display: none;
        }
      }
    }
  }

  .footer_address_wrapper {
    display: flex;
    gap: 30px;
    width: 100%;
    padding: 25px 0;

    @include tablet {
      flex-flow: column;
    }

    .footer_address {
      display: flex;
      flex: 1;
      flex-flow: column;
      gap: 0;
      align-items: flex-start;
      width: 100%;
      margin-top: auto;
      color: #919191;
      font-size: 12px;
      text-align: left;
      border: none;

      @include mobile-sm {
        margin-top: 20px;
        font-size: 11px;
      }

      .wall {
        display: inline-block;
        padding: 0 3px;
      }
    }

    .bottom_wrapper {
      @include flex(row, center, flex-end, 30px);

      height: fit-content;
    }
  }

  .links_wrapper {
    @include flex(row, center, flex-end, none);

    .menu_links {
      display: flex;
      gap: 24px;
      align-items: center;
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        &:last-child a {
          margin-right: 0;
        }

        img {
          width: 22px;
          height: 22px;

          @include mobile {
            width: 30px;
            height: 30px;
          }
        }

        a {
          display: flex;
          gap: 5px;
          align-items: center;
          color: #000;
          font-weight: 600;
          font-size: 15px;
          cursor: pointer;
        }
      }
    }
  }

  hr {
    width: 100%;
    margin: 0;
    border-color: #c4c4c4;
  }
}

.footer_dropdown {
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px;
  margin-top: 5px;
  background-color: $color-white;
  border: 1px solid $color-gray-400;

  div {
    display: flex;
    justify-content: center;
    width: 100%;

    button {
      width: 90%;
      height: 30px;
      border-bottom: 1px solid $color-gray-200;
      border-radius: 0;

      &:hover {
        color: $color-primary-default !important;
        font-weight: 800;
      }
    }

    &:last-child {
      button {
        border-bottom: none;
      }
    }
  }
}
