
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
[data-motion='scale-in'] {
  transform: translateY(40px);
  opacity: 0;
  transition: all 0.8s ease;

  &.motion-animate {
    transform: translateY(0);
    opacity: 1;
  }
}

[data-motion='stagger-in'] {
  & [data-motion-item] {
    transform: translateY(40px);
    opacity: 0;
    transition: all 0.8s var(--motion-delay, 0) ease;
  }

  &.motion-animate [data-motion-item] {
    transform: translateY(0);
    opacity: 1;
  }
}
