
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.divider {
  flex-shrink: 0;
  margin: 0;
  border-color: $color-gray-200;
  border-style: solid;
  border-width: 0 0 1px;
}
