
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.progress {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 4px;
  overflow: hidden;
  background-color: rgba($color-black, 0.1);
  border-radius: 9999px;

  .bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--progress-color);
    transform: translateX(calc(-100% + var(--progress-value) * 1%));
    transition: transform 0.1s;
  }

  &.loading {
    .bar {
      background: repeating-linear-gradient(
        -45deg,
        rgba($color-black, 0.1),
        rgba($color-black, 0.1) 8px,
        var(--progress-color) 8px,
        var(--progress-color) 24px
      );
      background-color: var(--progress-color);
      transform: translateX(0);
      opacity: 0.8;
      animation: bar 0.8s linear infinite;
    }
  }
}

@keyframes bar {
  to {
    background-position-x: 0;
  }

  from {
    background-position-x: -100px;
  }
}
