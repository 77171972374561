
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.wrapper {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  max-width: fit-content;
  padding: 3px 8px;
  border-radius: 20px;

  .text {
    overflow: hidden;
    line-height: 140%;
    white-space: nowrap;
    font: {
      size: 13px;
      weight: 500;
    }
  }

  &.chip_default {
    color: $color-gray-700;
    background-color: $color-gray-100;
  }

  &.chip_primary {
    color: $color-primary-default-hover;
    background-color: $color-primary-background-hover;
  }

  &.chip_alert {
    color: $color-red-default-hover;
    background-color: $color-red-background;
  }

  .slot {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;

    &:last-child {
      cursor: pointer;
    }
  }
}
