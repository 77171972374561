
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.table_wrapper {
  width: 100%;

  .table_scroll {
    width: 100%;
    margin-bottom: 24px;
    overflow: auto;
  }

  table {
    width: max-content;
    min-width: 100%;
    margin-bottom: 10px;
    color: $color-gray-700;
    text-align: left;

    @include body-1('r');

    thead tr {
      border-bottom: 1px solid $color-gray-200;
    }

    tr {
      height: 50px;
    }

    th {
      padding: 0 20px;
      color: #8c9097;

      @include body-1('b');
    }

    td {
      padding: 0 20px;
    }

    tbody tr:nth-child(odd) {
      background-color: $color-gray-50;
    }
  }
}
