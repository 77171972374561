
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
// !! 전체 서비스에 모든 영향을 미치는 컴포넌트입니다.
// !! 수정하려면, 컴포넌트 담당자와 미리 상의해주세요.

.dialog {
  position: fixed;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  inset: 0;

  &[data-dialog-drawer='true'] {
    @include mobile {
      --dialog-height: 100%;

      justify-content: flex-end;
      padding: calc(50px + max(env(safe-area-inset-top), 10px)) 0 0;
    }
  }
}

.header {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px 24px 16px;

  > * {
    flex: 1;
  }

  & + .content {
    padding-top: 0;
  }

  & + .footer {
    padding-top: 0;
  }

  .col {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .topSlot {
    @include h4;
  }

  .title {
    white-space: pre-line;

    @include h5;
  }

  .slot {
    display: flex;
    flex: none;
    gap: 10px;
    align-items: flex-start;
    height: 100%;
    line-height: 1;

    &.title {
      flex: 1;
    }
  }

  .close:focus-visible {
    border-radius: 4px;
    outline: none;
    box-shadow: $focus-ring;
  }

  @include tablet {
    padding: 24px 16px 16px;
  }
}

.content {
  flex: 1;
  padding: 24px;
  overflow: hidden auto;

  &.pre_line {
    white-space: pre-line;
  }

  @supports (overflow: overlay) {
    overflow: hidden overlay;
  }

  @include body-1('r');

  @include tablet {
    padding: 24px 16px;
  }

  & ~ .footer {
    padding-top: 0;
  }
}

.footer {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 24px;

  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: calc(100% + 24px);
    background: linear-gradient(180deg, rgb(255 255 255 / 0%) 0%, #fff 24%);
    content: '';
  }

  > * {
    position: relative;
    z-index: 1;
    flex: 1;
  }

  &.vertical > * {
    flex: 0 0 100%;
  }

  @include tablet {
    padding: 24px 16px;
  }
}
