
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.item {
  display: flex;
  gap: 4px;
  align-items: center;
  width: 100%;
  min-height: 40px;
  padding: 0 12px;
  color: $color-gray-700;

  @include body-1('sb');

  line-height: 1;
  text-align: left;
  background-color: $color-white;
  cursor: pointer;

  &[data-headlessui-state='active'] {
    background-color: $color-gray-50;
  }

  &.selected {
    color: $color-primary-default;
    background-color: $color-primary-background;
  }

  &.error {
    color: $color-red-default;
  }

  &.disabled {
    color: $color-gray-400;
  }

  .subtitle {
    color: $color-gray-500;

    @include caption-1('sb');
  }

  .with_subtitle {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .text {
    flex: 1;
  }
}
