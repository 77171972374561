
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.toast_container {
  bottom: 100px !important;

  @include mobile {
    bottom: 16px !important;
  }

  .toast {
    display: inline-flex;
    align-items: center;
    min-height: 40px;
    padding: 8px 16px;
    color: $color-white !important;
    background-color: rgba($color-black, 0.7) !important;
    border-radius: 8px;
    backdrop-filter: blur(0.75px);

    @include subtitle('m');
  }
}
