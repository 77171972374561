
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.trigger {
  display: inline-flex;
  vertical-align: middle;
}

.tooltip {
  z-index: 100;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 30px;
  padding: 4px 6px;
  color: $color-gray-200;
  background-color: rgba($color-gray-700, 80%);
  border-radius: 4px;

  @include body-2('m');
}
