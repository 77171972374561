
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
/* stylelint-disable scss/at-rule-no-unknown */

@import '../../../../libs/common/ui/styles/globals';
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply font-pretendard antialiased text-gray-700;
  }

  :root {
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }
}

[lang='ja'] {
  body {
    font-family: 'Pretendard JP', $font-sans;
  }
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  color: $color-gray-700;
  font-size: 14px;
  font-family: $font-sans;
  -webkit-touch-callout: none;
  -ms-overflow-style: none;
  scroll-behavior: unset !important;

  // scrollbar styles (firefox)
  * {
    scrollbar-color: rgb(0 0 0 / 20%) transparent;
    scrollbar-width: thin;
  }

  // scrollbar styles (chrome)
  ::-webkit-scrollbar {
    width: 14px;
    height: 14px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba($color-gray-400, 0.5);
    background-clip: padding-box;
    border: 5px solid transparent;
    border-radius: 9999px;

    &:hover {
      background-color: $color-gray-400;
    }
  }

  @include tablet {
    font-size: 13px;
  }

  @include mobile-sm {
    font-size: 11px;
  }
}

body {
  -webkit-font-smoothing: antialiased;
}

.use-typography {
  font-size: 16px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0;
}

h4,
.h4 {
  font-size: 18px;
}

a {
  color: inherit;
  text-decoration: none;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border-color: initial;
  border-style: initial;
  border-width: 1px 0 0;
  border-top-color: rgb(238 238 238);
  border-top-style: solid;
  border-image: initial;
  opacity: 0.25;
}

label {
  display: inline-block;
  max-width: 100%;
  font-weight: 600;
}

button {
  background: none;
  border: none;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #eee;
  cursor: not-allowed;
  opacity: 1;
}

.form-control {
  border-radius: 8px;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.hide_mobile_display {
  @include mobile {
    display: none;
  }
}

.show_mobile_display {
  display: none;

  @include mobile {
    display: block;
    margin: 0;
  }
}

.modal-content {
  border-radius: 8px;
}

.container {
  width: 90%;
  max-width: $container-max-width;
  margin: auto;
  padding: 60px 0;

  @include mobile {
    padding: 30px 0;
  }

  @include mobile-sm {
    padding: 10px 0;
  }
}

#__next {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

#content {
  width: 90%;
  max-width: $container-max-width;
  min-height: calc(100vh - 150px);
  margin: 0 auto;
  padding-top: 70px;
}

.flex_row_center {
  @include flex(row, center, center, none);
}

.border {
  @include border(1px, solid, $color-gray-200);

  &_top {
    border-top: 1px solid $color-gray-200;
  }

  &_bottom {
    border-bottom: 1px solid $color-gray-200;
  }

  &_left {
    border-left: 1px solid $color-gray-200;
  }

  &_right {
    border-right: 1px solid $color-gray-200;
  }
}

.hidden {
  visibility: hidden !important;
}

.form-control.is-invalid {
  background-image: none;
  border-color: #ff5353;
}

.modal-header {
  border-bottom: none;
}

.fade-enter {
  transform: translateY(-5px);
  opacity: 0;
}

.fade-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: opacity 100ms, transform 100ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 350ms;
}

.fade-exit-done {
  display: none;
}

.hide,
.zep-key,
.binding-key {
  display: none !important;
}

.text_underline {
  text-decoration: underline;
}

.contents {
  min-height: calc(100vh - 320px);
}

.disabled {
  cursor: default !important;
}

label.disabled {
  color: $color-gray-300;
}

.story_wrapper {
  display: flex;
  gap: 10px;
  margin: 1em;
}

// Tooltip 컴포넌트의 play 유형에서만 사용
.kbd {
  display: block;
  background-color: $color-primary-default;
  border-radius: 2px;

  @include space-x(2px);

  kbd {
    background-color: inherit;
  }
}

.close_button {
  &:hover {
    fill: $primary-gradient-purple;
  }

  cursor: pointer;
}

// Feedback 버튼 웹 반응형에서 모바일로 변경 시 숨김처리
#launcher {
  @include mobile {
    display: none;
  }
}

// 로그인 페이지에 grecaptcha 뱃지를 구글이 강제로 띄우는 것을 숨김처리
.grecaptcha-badge {
  visibility: hidden;
}

main {
  flex: 1;
}
