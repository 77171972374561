
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.profile {
  position: relative;
  line-height: 1;

  .avatar {
    position: relative;
    display: inline-flex;
    width: var(--profile-size);
    height: var(--profile-size);
    overflow: hidden;
    background-color: var(--profile-background-color, $color-white);
    border-radius: 50%;

    .component {
      position: absolute;
      top: 50%;
      left: 50%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .empty {
    align-items: flex-end;
    justify-content: center;

    .person {
      aspect-ratio: 26 / 28;

      > svg {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .badge {
    position: absolute;
    right: 0;
    bottom: 2px;
    width: var(--badge-size);
    height: var(--badge-size);
    background-color: $color-green-default;
    border-radius: 9999px;
    box-shadow: 0 0 0 1px $color-white;
  }
}

.size {
  &_xs {
    --profile-size: #{20px};
    --badge-size: #{4px};

    .component {
      transform: translate(-49%, -46%) scale(0.45);
      image-rendering: auto;
    }

    .empty .person {
      width: 16px;
    }
  }

  &_sm {
    --profile-size: #{24px};
    --badge-size: #{6px};

    .component {
      transform: translate(-49%, -46%) scale(0.55);
      image-rendering: auto;
    }

    .empty .person {
      width: 20px;
    }
  }

  &_md {
    --profile-size: #{34px};
    --badge-size: #{8px};

    .component {
      transform: translate(-49%, -44%) scale(0.8);
      image-rendering: auto;
    }

    .empty .person {
      width: 28px;
    }
  }

  &_lg {
    --profile-scale: 0.9;
    --profile-size: #{38px};
    --badge-size: #{10px};

    .component {
      transform: translate(-49%, -42%) scale(0.9);
      image-rendering: auto;
    }

    .empty .person {
      width: 32px;
    }
  }

  &_xl {
    --profile-scale: 1;
    --profile-size: #{48px};
    --badge-size: #{12px};

    .component {
      transform: translate(-49%, -42%) scale(1.1);
      image-rendering: auto;
    }

    .empty .person {
      width: 38px;
    }
  }

  &_2xl {
    --profile-scale: 1.3;
    --profile-size: #{62px};
    --badge-size: #{14px};

    .component {
      transform: translate(-49%, -38%) scale(1.5);
      image-rendering: auto;
    }

    .empty .person {
      width: 50px;
    }
  }
}
