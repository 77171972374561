
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.accordion {
  background-color: $color-white;
  border: 1px solid $color-gray-200;
  border-radius: 8px;

  .button {
    width: 100%;
    padding: 0;

    .button_field {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 16px;
      color: $color-gray-700;
      font-size: 16px;
      text-align: left;
      outline: none;

      strong {
        font-weight: bold;
      }

      @include space-x(18px);

      .text {
        display: inline-flex;
        flex: 1;
        gap: 4px;
        align-items: center;
        font-size: 14px;
        line-height: 1;

        &.info {
          color: $color-primary-default;
        }

        &.danger {
          color: $color-red-default;
        }

        &.success {
          color: $color-mint-dark;
        }
      }

      .icon {
        transform: rotate(-180deg);

        @include space-x(4px);
      }
    }
  }

  .panel {
    padding: 16px;
  }

  &.open {
    background-color: $color-gray-50;

    .panel {
      padding-top: 0;
    }

    .button .icon {
      transform: rotate(0);
    }
  }
}
