// Font
$font-sans: 'Pretendard Std', 'Pretendard', 'Pretendard JP', -apple-system,
  blinkmacsystemfont, system-ui, roboto, 'Helvetica Neue', 'Segoe UI',
  'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;
$font-weights: (
  'r': 400,
  'm': 500,
  'sb': 600,
  'b': 700,
  'eb': 800,
);

// 웹접근성: 키보드 접근 포커스 스타일
$focus-ring: 0 0 0 1px rgba($color-primary-default, 20%),
  0 0 0 4px rgba($color-primary-default, 8%);

// 이 색상은 디자인 시스템에 포함되어 있지 않음. 추후 디자이너 문의 후 토큰 추가 후 제거해야 함
$red-disable: #fff8fb;
$container-max-width: 1140px;
