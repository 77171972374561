
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.toast {
  display: inline-flex;
  align-items: center;
  min-height: 40px;
  padding: 8px 16px;
  color: $color-white;
  line-height: 1;
  background-color: rgba($color-black, 0.7);
  border-radius: 8px;
  backdrop-filter: blur(0.75px);

  @include subtitle('m');

  .slot {
    &:first-of-type {
      margin-right: 4px;
    }

    &:last-of-type {
      margin-left: 24px;

      @include subtitle('sb');
    }

    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
