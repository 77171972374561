
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
// !! 전체 서비스에 모든 영향을 미치는 컴포넌트입니다.
// !! 수정하려면, 컴포넌트 담당자와 미리 상의해주세요.

// Alert, Confirm 다이얼로그에 한하여 적용
.header {
  padding-bottom: 32px;
}
