// 👉 figma link: https://www.figma.com/file/ORIPtydAYEVIrAy0vZ0f4t/%F0%9F%8E%A8ZEP-Components?node-id=3928%3A56983&t=QVByFrZ656teyo8H-0
@use 'sass:map';

@mixin h1 {
  font-weight: map.get($font-weights, 'eb');
  font-size: 34px;
  line-height: 1.3;
}

@mixin h2 {
  font-weight: map.get($font-weights, 'eb');
  font-size: 30px;
  line-height: 1.3;
}

@mixin h3 {
  font-weight: map.get($font-weights, 'eb');
  font-size: 24px;
  line-height: 1.3;
}

@mixin h4 {
  font-weight: map.get($font-weights, 'b');
  font-size: 20px;
  line-height: 1.4;
}

@mixin h5 {
  font-weight: map.get($font-weights, 'b');
  font-size: 18px;
  line-height: 1.4;
}

@mixin subtitle($weight) {
  font-weight: map.get($font-weights, $weight);
  font-size: 16px;
  line-height: 1.4;
}

@mixin body-1($weight) {
  font-weight: map.get($font-weights, $weight);
  font-size: 14px;
  line-height: 1.4;
}

@mixin body-2($weight) {
  font-weight: map.get($font-weights, $weight);
  font-size: 13px;
  line-height: 1.4;
}

@mixin caption-1($weight) {
  font-weight: map.get($font-weights, $weight);
  font-size: 12px;
  line-height: 1.4;
}

@mixin caption-2 {
  font-weight: map.get($font-weights, 'm');
  font-size: 11px;
  line-height: 1.4;
}

@mixin caption-3 {
  font-weight: map.get($font-weights, 'sb');
  font-size: 10px;
  line-height: 1.4;
}
