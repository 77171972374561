@use 'sass:map';

.shadow {
  &-s {
    @include shadow-s;

    &-reverse {
      @include shadow-s-reverse;
    }
  }

  &-m {
    @include shadow-m;

    &-reverse {
      @include shadow-m-reverse;
    }
  }

  &-l {
    @include shadow-l;

    &-reverse {
      @include shadow-l-reverse;
    }
  }

  &-xl {
    @include shadow-xl;

    &-reverse {
      @include shadow-xl-reverse;
    }
  }

  &-2xl {
    @include shadow-2xl;

    &-reverse {
      @include shadow-2xl-reverse;
    }
  }
}

.text {
  // typography
  &-h1 {
    @include h1;
  }

  &-h2 {
    @include h2;
  }

  &-h3 {
    @include h3;
  }

  &-h4 {
    @include h4;
  }

  &-h5 {
    @include h5;
  }

  &-subtitle-sb {
    @include subtitle('sb');
  }

  &-subtitle-m {
    @include subtitle('m');
  }

  &-subtitle-r {
    @include subtitle('r');
  }

  &-body-1-b {
    @include body-1('b');
  }

  &-body-1-sb {
    @include body-1('sb');
  }

  &-body-1-m {
    @include body-1('m');
  }

  &-body-1-r {
    @include body-1('r');
  }

  &-body-2-b {
    @include body-2('b');
  }

  &-body-2-sb {
    @include body-2('sb');
  }

  &-body-2-m {
    @include body-2('m');
  }

  &-body-2-r {
    @include body-2('r');
  }

  &-caption-1 {
    @include caption-1('r');
  }

  &-caption-2 {
    @include caption-2;
  }

  &-caption-3 {
    @include caption-3;
  }

  // colors
  &-primary {
    color: $color-primary-default;
  }

  &-danger {
    color: $color-red-default;
  }

  // etc
  &-link {
    color: $color-blue-default;
    text-decoration: underline;
  }
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@for $i from 1 through 4 {
  .text-clamp-#{$i} {
    @include text-clamp($i);
  }
  .text-clamp-#{$i}\! {
    @include text-clamp($i, false, true);
  }
}

.cursor-pointer {
  cursor: pointer;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

// 웹접근성: 키보드 접근 포커스 스타일
.focus-ring:focus-visible {
  outline: none;
  box-shadow: $focus-ring;
}

// Color: 색상 클래스명을 생성하기 위해 맵핑 코드 입니다.
// 이 유틸리티 클래스는 `color-gray-50` 이렇게 넣어 사용할 수 있습니다.
$color-values: 50, 100, 200, 300, 400, 500, 600, 700;
$colors: (
  50: $color-gray-50,
  100: $color-gray-100,
  200: $color-gray-200,
  300: $color-gray-300,
  400: $color-gray-400,
  500: $color-gray-500,
  600: $color-gray-600,
  700: $color-gray-700,
);

@each $value in $color-values {
  .color-gray-#{$value} {
    color: map.get($colors, $value);
  }
}
