
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.list {
  min-width: 140px;
  padding: 8px 0;
  background-color: $color-white;
  border-radius: 8px;
  outline: none;

  @include shadow-s;
}
