
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.header {
  position: sticky;
  top: 0;
  z-index: 11;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 64px;
  font-size: 14px;
  background-color: white;
  box-shadow: 0 2px 8px rgb(0 0 0 / 0%);
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 0.2, 1);

  @include mobile {
    min-height: 50px;
  }

  @at-root :global(.gnb_no_shadow) & {
    box-shadow: none;
  }

  &_movement {
    box-shadow: 0 2px 8px rgb(0 0 0 / 14%);
  }

  @at-root :global([data-stuck-plan-select='true']) & {
    box-shadow: none;
  }

  .contents {
    position: relative;
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    max-width: $container-max-width;
    margin: auto;
    background-color: $color-white;

    .signIn_button {
      @include tablet {
        display: none;
      }
    }

    .nav_active::after {
      @include tablet {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: rgb(0 0 0 / 40%);
        content: '';
      }
    }

    .nav_wrapper {
      display: flex;
      gap: 26px;
      align-items: center;
      justify-content: flex-start;
      width: 100%;

      .logo {
        z-index: 9;
        display: flex;
        width: fit-content;
        cursor: pointer;

        img {
          min-width: 84px;
          max-width: 132px;
        }

        @include mobile {
          img {
            min-width: 64px;
            height: 24px;
          }
        }
      }

      .desktop_nav {
        width: calc(100% - (40px + 84px));
        color: $color-gray-700;
        font-weight: 600;
        font-size: 14px;

        &.desktop_nav_edu {
          width: calc(100% - (40px + 132px));
        }

        @include tablet {
          display: none;
        }

        @include mobile {
          height: 100%;
        }

        .nav_button_wrapper {
          display: flex;
          gap: 32px;
          width: fit-content;

          .menu_button {
            display: flex;
            gap: 4px;
            align-items: center;
            width: max-content;
            font-weight: 600;

            button {
              padding: 0;
              font-weight: inherit;
            }
          }
        }
      }

      // 모바일 메뉴
      .mobile_nav {
        display: none;

        @include tablet {
          position: fixed;
          top: 0;
          left: 0;
          z-index: 999;
          display: flex;
          flex-direction: column;
          width: 100%;
          height: auto;
          margin-left: 0;
          font-size: 18px;
          background-color: $color-white;
          transform: translateY(-100%);
          opacity: 0;
          transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);

          @include mobile {
            height: 100%;
            transform: translateX(100%);
          }

          .mobile_header {
            display: flex;
            flex: none;
            align-items: center;
            justify-content: space-between;
            height: 64px;
            padding: 0 20px;

            @include mobile {
              height: 50px;
            }

            .close_button {
              padding: 0;
            }
          }

          .mobile_contents {
            flex: 1;
            width: 100%;
            padding: 0 20px;
            overflow: auto;

            a {
              display: flex;
              align-items: center;
              color: $color-gray-700;
              font-weight: 600;
              border-radius: 8px;
            }

            .mobile_menu {
              display: flex;
              padding-top: 2px;
              font-size: 15px;

              & > a {
                height: 46px;
                padding: 0 6px;
              }

              &_mini {
                display: flex;
                align-items: center;
                height: 32px;
                padding: 0 6px;
                color: $color-gray-500;
                font-weight: 500;
                font-size: 13px;
              }

              &_sub {
                display: grid;
              }

              .mobile_subMenu {
                display: flex;
                flex-flow: wrap;
                margin-bottom: 4px;

                @include mobile {
                  flex-wrap: wrap;
                }

                & > a {
                  width: 33.3%;
                  height: 38px;
                  padding: 10px 6px;

                  @include space-x(8px);

                  @include mobile {
                    width: 50%;
                  }

                  &:hover {
                    color: $color-gray-700;
                    background-color: $color-primary-background-hover;
                  }
                }
              }

              .contour_line {
                height: 1px;
                margin: 4px;
                background-color: $color-gray-200;
              }
            }
          }

          .mobile_footer {
            display: flex;
            flex-flow: column-reverse;

            // reverse 속성 값을 사용 할 경우에는 space-y() 믹스인 사용 불가로 gap 속성을 사용 하였습니다
            gap: 12px;

            @include tablet {
              width: 100%;
              padding: 24px;
            }

            & button {
              display: flex;
              width: 100%;
            }
          }
        }
      }

      .show_mobile_nav {
        transform: translateX(0);
        opacity: 1;
      }
    }

    .flex_display {
      display: flex;
      align-items: center;

      @include space-x(10px);

      @include tablet {
        .create_button {
          display: none;
        }
      }

      .nav_mobile_button {
        display: none;

        @include tablet {
          display: flex;
          height: 28px;
          all: unset;
        }

        img {
          cursor: pointer;
        }
      }

      .language_filter_button {
        padding: 8px;
        border: 1px solid $color-gray-200;
        border-radius: 4px;
      }

      .login_user_menu {
        width: max-content;

        & > button {
          display: flex;
          align-items: center;
          border: 1px solid transparent;

          span {
            display: block;
            max-width: 102px;
            padding: 6px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .dropdown_menu_end {
          min-width: 260px;
          font-weight: 500;
          font-size: 13px;
          background-color: $color-white;
          border-radius: 8px;
          box-shadow: 0 2px 6px rgb(0 0 0 / 10%);

          .menu_top {
            display: flex;
            flex-flow: column;
            padding: 0 16px;

            @include space-y(6px);

            > span {
              margin-bottom: 8px;
              color: $color-gray-600;
              font-size: 12px;
              line-height: 14px;
            }

            .zem_menu {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .current_zem {
                display: flex;
                align-items: center;
                color: $color-gray-700;
                font-weight: 600;
                font-size: 0.929rem;

                @include space-x(10px);
              }

              a {
                width: auto;
                color: $color-primary-default;
              }
            }
          }

          .menu_middle {
            display: flex;
            flex-flow: column;

            a,
            .user_menu_button {
              display: flex;
              gap: 4px;
              align-items: center;
              width: 100%;
              padding: 8px 16px;
              color: $color-gray-700;
              cursor: pointer;

              &:hover {
                background-color: $color-primary-background;
              }
            }
          }

          .contour_line {
            display: flex;
            height: 1px;
            margin: 0 16px;
            background-color: $color-gray-200;
          }
        }
      }
    }

    .header_menu {
      white-space: nowrap;

      &.hidden {
        visibility: hidden;
      }

      .gnb_menu {
        padding: 20px 0;

        &:hover {
          a {
            color: $color-primary-default;

            + .menu_icon {
              transform: rotate(180deg);
              filter: brightness(0) saturate(100%) invert(33%) sepia(57%)
                saturate(1954%) hue-rotate(227deg) brightness(99%)
                contrast(106%);
            }
          }

          .menu_items {
            display: flex !important;

            a {
              color: $color-gray-700;
            }
          }
        }

        .menu_items {
          display: none;
        }
      }

      .menu_button {
        font-weight: 600;
        cursor: pointer;

        a {
          color: $color-gray-700;
        }

        .active_item {
          color: $color-primary-default;
        }
      }

      .menu_items {
        position: absolute;
        top: 52px;
        z-index: 1;
        flex-flow: column;
        min-width: 180px;
        padding: 16px 0;
        overflow: hidden;
        background-color: $color-white;
        border-radius: 4px;
        outline: none;
        box-shadow: 1px 1px 6px rgb(0 0 0 / 7.3%);
        animation: fade-in-right 0.1s linear;

        @include tablet {
          top: 52px;
        }

        @include mobile {
          top: 35px;
        }

        &.language_items {
          min-width: 120px;
        }

        &.dropdown_menu_end {
          right: 0;

          @include space-y(8px);
        }

        a,
        .user_menu_button {
          display: flex;
          gap: 4px;
          align-items: center;
          width: 100%;
          padding: 8px 16px;
          color: $color-gray-700;
          cursor: pointer;

          &:hover {
            background-color: $color-primary-background;
          }
        }

        .menu_hover {
          background-color: $color-primary-background;
        }
      }
    }
  }
}

@keyframes fade-in-right {
  0% {
    transform: translateY(-3px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
