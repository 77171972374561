
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
// !! 전체 서비스에 모든 영향을 미치는 컴포넌트입니다.
// !! 수정하려면, 컴포넌트 담당자와 미리 상의해주세요.

$ease-out-quint: cubic-bezier(0.22, 1, 0.36, 1);

[data-dialog-drawer='true'] {
  @include mobile {
    .panel {
      max-width: 100%;
      padding-bottom: env(safe-area-inset-bottom);
      border-radius: 16px 16px 0 0;

      @at-root :global([data-virtual-keyboard-visible='true']) & {
        padding-bottom: 0;
      }
    }

    .enter {
      &_from {
        transform: translateY(100%);
        opacity: 1;
      }

      &_to {
        transform: translateY(0);
        opacity: 1;
      }
    }

    .leave {
      &_from {
        transform: translateY(0);
        opacity: 1;
      }

      &_to {
        transform: translateY(100%);
        opacity: 1;
      }
    }
  }
}

.panel {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: var(--dialog-max-width, 400px);
  height: calc(var(--dialog-height, auto) + env(safe-area-inset-bottom));
  max-height: calc(
    var(--dialog-max-height, 100%) + env(safe-area-inset-bottom)
  );
  overflow: hidden;
  color: $color-gray-700;
  overflow-wrap: anywhere;
  background-color: #fff;
  border-radius: 8px;
  outline: none;

  @include shadow-l;

  @include tablet {
    @include shadow-m;
  }
}

.enter {
  transition: all 0.25s $ease-out-quint;

  &_from {
    transform: translateY(0.5rem);
    opacity: 0;
  }

  &_to {
    transform: translateY(0);
    opacity: 1;
  }
}

.leave {
  transition: all 0.25s $ease-out-quint;

  &_from {
    transform: translateY(0);
    opacity: 1;
  }

  &_to {
    transform: translateY(0.5rem);
    opacity: 0;
  }
}
