
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.textarea {
  @include space-x(8px);

  display: flex;
  align-items: center;
  overflow: auto;
  color: $color-gray-700;
  background-color: $color-white;
  border: 1px solid $color-gray-300;
  border-radius: 8px;
  outline: none;
  resize: none;
  scroll-padding-block: 10px;

  @supports (overflow: overlay) {
    overflow: overlay;
  }

  &:focus-visible {
    border: 1px solid $color-primary-default;
  }

  &.error {
    border-color: $color-red-default;
  }

  &.disabled {
    color: $color-gray-400;
    background-color: $color-gray-100;
    border-color: $color-gray-200;
  }

  &.read_only {
    color: $color-gray-700;
    background-color: $color-gray-50;
    border-color: $color-gray-200;
  }

  &.fullWidth {
    display: flex;
    width: 100%;
  }

  &.size {
    &_md {
      padding: 8px 12px;

      @include subtitle('m');

      @include mobile {
        padding: 8px 12px;

        @include body-1('m');
      }
    }

    &_sm {
      padding: 8px 10px;

      @include body-2('m');

      @include mobile {
        padding: 8px 12px;

        @include body-1('m');
      }
    }
  }

  &::placeholder {
    color: $color-gray-500;
  }

  &:placeholder {
    text-overflow: ellipsis;
  }

  &:placeholder-shown {
    text-overflow: ellipsis;
  }

  // MEMO: https://developer.mozilla.org/en-US/docs/Web/CSS/:autofill
  &:autofill {
    background-color: transparent;
  }
}
