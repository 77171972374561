
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.header {
  display: flex;
  align-items: flex-end;

  .left {
    flex: 1;
  }

  .label {
    position: relative;
    display: flex;
    flex: 1;
    gap: 4px;
    align-items: center;

    @include subtitle('sb');

    .required {
      color: $color-red-default;
    }

    & + .description {
      margin-top: 2px;
    }
  }

  .right_slot {
    @include caption-1('r');

    color: $color-gray-600;
  }

  & + .content {
    margin-top: 8px;
  }
}

.description {
  @include caption-1('r');

  color: $color-gray-600;
  white-space: pre-line;

  & + .content {
    margin-top: 8px;
  }
}

.content {
  & + .helper_text {
    margin-top: 6px;
  }
}

.helper_text {
  margin-top: 6px;
  padding-left: 8px;
  color: $color-gray-500;
  white-space: pre-line;

  @include caption-1('r');

  &.error {
    color: $color-red-default;
  }
}

@mixin mobile-size {
  @include mobile {
    .header .label {
      @include body-1('sb');
    }

    .right_slot {
      @include caption-1('r');
    }

    .helper_text {
      @include caption-1('r');
    }
  }
}

.size {
  &_md {
    .header .label {
      @include subtitle('sb');
    }

    .right_slot {
      @include caption-1('r');
    }

    .helper_text {
      @include caption-1('r');
    }

    @include mobile-size;
  }

  &_sm {
    .header .label {
      @include body-1('sb');
    }

    .right_slot {
      @include caption-1('r');
    }

    .helper_text {
      @include caption-1('r');
    }

    @include mobile-size;
  }
}
