
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.sliderRoot {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  user-select: none;
  touch-action: none;

  &[data-orientation='horizontal'] {
    flex-direction: row;
    padding: 8px 0;
  }

  &[data-orientation='vertical'] {
    flex-direction: column;
    padding: 0 8px;
  }
}

.sliderTrack {
  position: relative;
  flex-grow: 1;
  background-color: $color-gray-300;
  border-radius: 2px;

  &[data-orientation='horizontal'] {
    height: 4px;
  }

  &[data-orientation='vertical'] {
    width: 4px;
  }

  .sliderRange {
    position: absolute;
    background-color: $color-primary-default;
    border-radius: 2px;

    &[data-orientation='horizontal'] {
      height: 100%;
    }

    &[data-orientation='vertical'] {
      width: 100%;
    }
  }
}

.sliderThumb {
  display: block;
  width: 16px;
  height: 16px;
  background-color: $color-primary-default;
  border: 2px solid $color-primary-background;
  border-radius: 50%;
  outline: none;
  box-shadow: 0 1px 4px rgb(42 39 65 / 8%);
  transition: box-shadow 0.1s;

  &:hover {
    background-color: $color-primary-default-hover;
  }

  &:active,
  &:focus-visible {
    background-color: $color-primary-default-hover;
    box-shadow: 0 0 0 5px rgba($color-primary-default, 0.08);
  }

  .sliderTooltip {
    position: absolute;
    padding: 4px 6px;
    color: #d5d9e0;
    font-weight: 500;
    font-size: 11px;
    white-space: nowrap;
    background-color: rgb(0 0 0 / 70%);
    border-radius: 4px;
    outline: none;
    opacity: 0;
    transition: all 0.2s;

    &.sliderTooltipVertical {
      top: 50%;
      right: 100%;
      margin-right: 4px;
      transform: translateY(-50%);
    }

    &.sliderTooltipHorizontal {
      bottom: 100%;
      left: 50%;
      margin-bottom: 4px;
      transform: translateX(-50%);
    }
  }

  &:hover,
  &:active,
  &:focus-visible {
    .sliderTooltip {
      opacity: 1;
    }
  }
}

.sliderMarker {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: $color-gray-500;
  font-weight: 500;
  font-size: 11px;
  white-space: nowrap;

  .sliderMarkerCircle {
    width: 6px;
    height: 6px;
    margin-top: -1px;
    margin-bottom: 6px;
    background-color: $color-gray-400;
    border-radius: 50%;

    &.sliderMarkerCircleActive {
      background-color: $color-primary-default-hover;
    }
  }
}
