
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.wrapper {
  position: fixed;
  bottom: 0;
  z-index: 10000;
  display: flex;
  width: 100%;
  height: 94px;
  padding: 20px 40px;
  background: $color-white;
  border-top: 1px solid $color-gray-100;
  box-shadow: 0 -1px 4px 0 rgb(42 39 65 / 8%);

  @media (width <= 1550px) {
    padding: 20px 40px;
  }

  @include tablet {
    padding: 20px 40px;
  }

  @include mobile {
    height: auto;
    padding: 20px 16px;
  }
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: $breakpoint-laptop;
  margin: 0 auto;
  overflow: hidden;

  @include tablet {
    gap: 20px;
  }

  @include mobile {
    flex-direction: column;
    gap: 20px;
  }
}

.gap {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

.text {
  flex: 1;
  max-width: 75%;
  max-height: 54px;
  overflow: hidden;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.4;
  text-overflow: ellipsis;
  overflow-wrap: anywhere;

  > a {
    padding-left: 4px;
    color: $color-blue-default;
  }

  @include body-1('r');

  @include tablet {
    display: block;
    padding-left: 0;
  }

  @include mobile {
    display: block;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    max-height: 1000px;
  }
}

.buttons {
  display: flex;
  flex: none;
  gap: 8px;
  align-items: center;
  width: 160px;

  @include mobile {
    flex-direction: column;
    width: 100%;
  }
}

.button {
  width: auto;
  min-width: 88px;
}
