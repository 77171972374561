
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.overlay {
  position: fixed;
  z-index: 0;
  background-color: rgba($color-black, 0.24);
  inset: 0;
}

.enter {
  transition: all 0.25s ease-out;

  &_from {
    opacity: 0;
  }

  &_to {
    opacity: 1;
  }
}

.leave {
  transition: all 0.25s ease-out;

  &_from {
    opacity: 1;
  }

  &_to {
    opacity: 0;
  }
}
