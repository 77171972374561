
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.dock {
  position: relative;
  display: flex;
  align-items: center;
  padding: 4px;
  color: $color-white;
  background-color: rgba($color-gray-700, 64%);
  border-radius: 8px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;

  @include space-x(4px);

  .button {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 60px;
    min-height: 40px;
    padding: 2px 6px;
    white-space: nowrap;
    border-radius: 4px;
    outline: none;

    @include space-y(2);

    &:hover {
      background-color: rgba($color-white, 0.1);
    }

    &.active {
      background-color: rgba($color-white, 0.1);
    }

    &:focus-visible {
      background-color: rgba($color-white, 0.16);
    }

    &:disabled {
      background-color: rgba($color-white, 0);
      opacity: 0.64;
    }

    &.red {
      color: $color-red-default;

      &:disabled {
        color: rgba($color-red-default, 0.6);
      }
    }

    .title {
      @include caption-3;
    }
  }
}
