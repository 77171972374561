
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0 0 0 / 50%);

  .loading_content {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 3000;
    margin: 0;
    background-color: $color-white;
    border-radius: 10px;
    transform: translate(-50%, -50%);

    .loading_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-width: 150px;
      height: auto;
      min-height: 150px;
      padding: 32px 20px;

      .svg {
        position: relative;
        width: 100px;
        height: 100px;
        animation: rotate 2s linear infinite;

        .circle {
          width: 100%;
          height: 100%;
          animation: animate 3s linear infinite;
          fill: none;
          stroke: $color-primary-default;
          stroke-width: 5;
          stroke-linecap: round;
          stroke-dasharray: 150;
          stroke-dashoffset: 150;
        }
      }
    }
  }
}

.info {
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: center;
  padding: 0 16px;
  text-align: center;
}

.text {
  color: $color-gray-600;
  font-weight: 700;
  font-size: 14px;
}

.desc {
  color: $color-gray-500;
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  white-space: pre-line;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate {
  0%,
  100% {
    stroke-dashoffset: 150;
  }

  50% {
    stroke-dashoffset: 0;
  }

  50.1% {
    stroke-dashoffset: 300;
  }
}
