
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.footer_banner {
  position: sticky;
  bottom: 0;
  z-index: 2;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 36px;
  background-color: #f2f9ff;
  border-top: 1px solid rgb(0 0 0 / 3%);
  cursor: pointer;

  @include tablet {
    display: none;
  }
}

.center_container {
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
  justify-content: center;
}

.text_wrapper {
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  justify-content: center;
}

.subtitle {
  color: $color-gray-default; // #5E6066
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
}

.title {
  color: $color-blue-default; // #0d94ff
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}
