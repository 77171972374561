
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.character {
  // 비율 계산을 위한 최대공약수(Greatest Common Divisor, GCD)
  --gcd: 0.75;

  // 시트에서 좌측부터 우측 끝까지 배치된 아바타의 총 개수
  --frame-row: 9;
  --frame-column: 4;
  --frame-start: 0;
  --frame-end: 0;
  --sheet-width: 432px;
  --avatar-width: calc(var(--sheet-width) / var(--frame-row));
  --avatar-height: calc(var(--avatar-width) / var(--gcd));

  position: relative;
  width: var(--avatar-width);
  height: var(--avatar-height);
  overflow: hidden;

  .object {
    position: absolute;
    inset: 0;
    background: var(--image-url) no-repeat;
    background-position: 0 0;
    background-size: var(--sheet-width) auto;
  }

  &.left .object {
    background-position: calc(var(--avatar-width) * 5 * -1) 0;
  }

  &.right .object {
    background-position: calc(var(--avatar-width) * 1 * -1)
      calc(var(--avatar-height) * -1);
  }

  &.back .object {
    background-position: calc(var(--avatar-width) * 6 * -1)
      calc(var(--avatar-height) * -1);
  }

  &front .object {
    background-position: 0 0;
  }

  &.jump.front .object {
    background-position: calc(var(--avatar-width) * 1 * -1)
      calc(var(--avatar-height) * 3 * -1);
  }

  &.jump.left .object {
    background-position: calc(var(--avatar-width) * 2 * -1)
      calc(var(--avatar-height) * 3 * -1);
  }

  &.jump.right .object {
    background-position: calc(var(--avatar-width) * 3 * -1)
      calc(var(--avatar-height) * 3 * -1);
  }

  &.jump.back .object {
    background-position: calc(var(--avatar-width) * 4 * -1)
      calc(var(--avatar-height) * 3 * -1);
  }

  &.sit.front .object {
    background-position: calc(var(--avatar-width) * 5 * -1)
      calc(var(--avatar-height) * 3 * -1);
  }

  &.sit.left .object {
    background-position: calc(var(--avatar-width) * 6 * -1)
      calc(var(--avatar-height) * 3 * -1);
  }

  &.sit.right .object {
    background-position: calc(var(--avatar-width) * 7 * -1)
      calc(var(--avatar-height) * 3 * -1);
  }

  &.sit.back .object {
    background-position: calc(var(--avatar-width) * 8 * -1)
      calc(var(--avatar-height) * 3 * -1);
  }

  .empty_profile {
    position: absolute;
    top: -50%;
    left: -50%;
    transform: translate(48%, 70%);
  }
}
