
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.scroll {
  position: relative;
  overflow: hidden;

  .viewport {
    width: 100%;
    height: 100%;
  }
}

.scrollbar {
  position: relative;
  z-index: 1;
  display: flex;
  padding: 4px;
  user-select: none;
  touch-action: none;

  .vertical {
    flex-direction: row;
  }

  .horizontal {
    padding: 4px;
  }
}

.thumb {
  position: relative;
  padding: 2px;
  background-color: $color-gray-400;
  border-radius: 9999px;

  &:hover {
    background-color: $color-gray-500;
  }
}
