
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.pagination_ui {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 34px;
  font-weight: 600;
  font-size: 1.1428rem;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 100%;
    margin: 0 4px;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: $color-gray-100;
    }

    &.control {
      background-color: $color-white;
      border: 1px solid $color-gray-200;

      &:hover {
        background-color: $color-gray-100;
      }
    }

    &.active {
      color: $color-white;
      background-color: $color-primary-default;
    }
  }
}
