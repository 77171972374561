@import 'https://fastly.jsdelivr.net/gh/orioncactus/pretendard@v1.3.6/dist/web/static/pretendard-std-dynamic-subset.css';
@import 'https://fastly.jsdelivr.net/gh/orioncactus/pretendard@v1.3.6/dist/web/static/pretendard-dynamic-subset.css';
@import 'https://fastly.jsdelivr.net/gh/orioncactus/pretendard@v1.3.6/dist/web/static/pretendard-jp-dynamic-subset.css';
@import 'variables';
@import 'preflight';
@import 'utilities';

// MEMO: 영어, 한글에만 단어 단위 줄바꿈을 적용
// 개발자 분들께 언어 변경 시 lang 속성이 변하도록 작업을 요청드려야 함
[lang='en'] body,
[lang='ko'] body {
  word-break: keep-all;
}

body {
  width: 100%;
  height: 100%;
  color: $color-gray-700;
  font-size: 16px;
  font-family: $font-sans;
  -webkit-font-smoothing: antialiased;
}

::selection {
  color: $color-white;
  background-color: $color-primary-default;
}

// scrollbar styles (firefox)
* {
  scrollbar-color: rgb(0 0 0 / 20%) transparent;
  scrollbar-width: thin;
}

// scrollbar styles (chrome)
::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba($color-gray-400, 0.5);
  background-clip: padding-box;
  border: 5px solid transparent;
  border-radius: 9999px;

  &:hover {
    background-color: $color-gray-400;
  }
}

:root {
  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-right: env(safe-area-inset-right);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  --safe-area-inset-left: env(safe-area-inset-left);
}
