
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
$ease-out-quint: cubic-bezier(0.22, 1, 0.36, 1);

.row {
  display: inline-flex;
  color: $color-gray-700;
  line-height: 1.2;
  vertical-align: middle;

  @include space-x(8px);

  .col {
    flex: 1;

    @include space-y(6px);
  }

  .checkbox {
    flex: none;
  }

  .label {
    @include body-1('sb');

    white-space: pre-line;
  }

  .text {
    @include caption-1('r');

    color: $color-gray-600;
    white-space: pre-line;
  }

  &.fullWidth {
    display: flex;
    width: 100%;
  }
}

.disabled {
  .label {
    color: $color-gray-400;
  }

  .checkbox {
    color: $color-gray-300;
  }
}

.checked.disabled {
  .label {
    color: $color-gray-600;
  }
}

.toggle {
  position: relative;
  z-index: 0;
  display: inline-flex;
  width: 42px;
  height: 24px;

  [data-checkbox-icon] {
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $color-gray-300;
    background-color: $color-gray-300;
    border-radius: 9999px;
    inset: 0;

    &::after {
      position: absolute;
      top: 3px;
      left: 4px;
      width: 18px;
      height: 18px;
      background-color: $color-white;
      border-radius: 50%;
      transition: left 0.14s $ease-out-quint;
      content: '';
    }
  }

  [data-checkbox-control] {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0;
    inset: 0;

    &:checked + [data-checkbox-icon] {
      background-color: $color-primary-default;

      &::after {
        left: 20px;
      }
    }

    &:disabled + [data-checkbox-icon] {
      background-color: $color-gray-200;

      &::after {
        background-color: $color-gray-50;
      }
    }

    &:checked:disabled + [data-checkbox-icon] {
      background-color: $color-primary-disabled;

      &::after {
        background-color: $color-gray-100;
      }
    }

    &:focus-visible + [data-checkbox-icon] {
      box-shadow: $focus-ring;
    }
  }
}
