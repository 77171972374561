
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.select {
  position: relative;
  z-index: 0;
  display: inline-flex;
  width: var(--select-width, auto);
  vertical-align: middle;

  .control {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    min-width: 100%;
    height: 100%;
    opacity: 0;

    &:focus + .button,
    &:hover + .button {
      border-color: $color-primary-default;
    }

    &:focus + .button,
    &:active + .button {
      box-shadow: 0 0 0 5px rgba($color-primary-default, 0.08);
    }
  }

  .button {
    display: inline-flex;
    align-items: center;
    width: 100%;
    min-width: 100px;
    padding: 0 12px;
    color: $color-gray-700;
    text-align: left;
    background-color: $color-white;
    border: 1px solid $color-gray-300;
    border-radius: 8px;
    outline: none;
    transition: box-shadow 0.1s;

    @include space-x(4px);

    .text {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &.empty {
        color: $color-gray-500;
      }
    }

    .icon {
      margin-right: -6px;
      transform: rotate(180deg);
    }

    &[data-headlessui-state*='open'] .icon {
      transform: rotate(0);
    }

    &.size {
      &_xs {
        min-height: 34px;

        @include body-2('m');
      }

      &_sm {
        min-height: 38px;

        @include body-1('m');
      }

      &_md {
        min-height: 40px;

        @include subtitle('m');
      }
    }

    &.disabled {
      color: $color-gray-400;
      background-color: $color-gray-100;
      border-color: $color-gray-300;
    }

    &.placeholder {
      color: $color-gray-500;
    }

    &.success {
      border-color: $color-green-default !important;
    }

    &.danger {
      border-color: $color-red-default !important;
    }
  }

  &.fullWidth {
    display: flex;
    width: 100%;
  }
}
